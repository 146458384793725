import "./Home.css";
import { useEffect } from "react"

import {
  Header,
  Cover,
  SectionEvents,
  SectionTickets,
  SectionPlaces,
  SectionMap,
  SectionSponsors,
  Footer
} from "../../Components";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="home-page">
    <Header />
    <Cover />
    <SectionEvents />
    <SectionMap />
    <SectionPlaces />
    <SectionTickets />
    <SectionSponsors />
    <Footer />
    </div>
  )
}

export default Home;
