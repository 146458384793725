import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

import './ImageViewer.css';

SwiperCore.use([Autoplay, Navigation]);

const ImageViewer = ({ images, isOpen, closeViewer, startIndex }) => {

  const [activeIndex, setActiveIndex] = useState(startIndex);

  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'visible';
    }
    setActiveIndex(startIndex);
  }, [isOpen, startIndex]);

  Modal.setAppElement('#root');

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: { zIndex: 1000, background: '#000000d9' },
        content: { background: 'transparent', border: 'none', overflow: 'hidden', display: 'flex', alignItems: 'center', inset: '0', padding: '0' }
      }}>
      <div className={"button modal-button"} onClick={() => closeViewer() }>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M312.1 375c9.369 9.369 9.369 24.57 0 33.94s-24.57 9.369-33.94 0L160 289.9l-119 119c-9.369 9.369-24.57 9.369-33.94 0s-9.369-24.57 0-33.94L126.1 256L7.027 136.1c-9.369-9.369-9.369-24.57 0-33.94s24.57-9.369 33.94 0L160 222.1l119-119c9.369-9.369 24.57-9.369 33.94 0s9.369 24.57 0 33.94L193.9 256L312.1 375z"/></svg>
      </div>
      <Swiper
        className={"image-viewer-swiper"}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        initialSlide={activeIndex}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}>
        {images.map((image, index) => (
          <SwiperSlide key={image.id} className={"slide"}>
            <img src={image.src} alt={`Slide ${index}`} className={"image"}/>
          </SwiperSlide>
        ))}
      </Swiper>
    </Modal>
  );
}

export default ImageViewer;