import "./Poster.css"

const Poster = (props) => {
  return (
    <div className={'poster ' + props.className}>
        <img src={props.src} alt="Poster" />
    </div>
  )
}

/*<div className="poster-info">
          <h1>{props.name}</h1>
          <h2>{props.date}</h2>
        </div>*/

export default Poster
