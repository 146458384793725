import "./SectionPlaces.css"

const SectionPlaces = () => {

  const handleClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="section-places" id="section-places">
      <div className="heading">
        <p className="red">TICKETS</p>
        <h1>Prodajna mesta</h1>
      </div>
      <div className="container">
      <div className="place">
        <div className="place-container" onClick={() => handleClick("https://maps.app.goo.gl/fQUqhZwS4G8TVX5SA")}>
          <h1>CLUB TRESOR</h1>
          <a className="red">Arthberplatz 2, 1100 Wien</a>
          <p className="working-time">SVAKOG RADNOG DANA OD 17:00h DO 20:30h</p>
        </div>
      </div>
      <div className="row">
        <div className="place">
          <div className="place-container" onClick={() => handleClick("https://maps.app.goo.gl/cPng1hD42FAAeJpd7")}>
            <h1>CAFE HIDE</h1>
            <a className="red">Römergasse 15, 1160 Wien</a>
          </div>
        </div>
        <div className="place">
          <div className="place-container" onClick={() => handleClick("https://maps.app.goo.gl/2cGbkiq1KRpRhAe3A")}>
            <h1>RESTAURANT CALYPSO</h1>
            <a className="red">Brunnerfeldstraße 65, 2380 Perchtoldsdorf</a>
          </div>
        </div>
        <div className="place">
          <div className="place-container" onClick={() => handleClick("https://maps.app.goo.gl/PmUSqdWG4ohfXa8q9")}>
            <h1>GIOVANNI</h1>
            <a className="red">Bäuerlegasse 3-5, 1200 Wien</a>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default SectionPlaces
