import "./SectionTickets.css"
import Upcoming from "../Tickets/Tickets"

const SectionTickets = () => {
  return (
    <div className="section-tickets" id="section-tickets">
      <div className="heading">
        <p className="red">LIVE CONCERT</p>
        <h1>Online tickets</h1>
      </div>
      <Upcoming/>
    </div>
  )
}

export default SectionTickets
