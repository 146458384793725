import "./Event.css"

const Event = (props) => {
  const clickMore = id => window.location.href = id;
  return (
        <div className="event-box">
            <h1 onClick={() => clickMore("#section-tickets")}>{props.name}</h1>
            <p>{props.date}, {props.location + " - SOLD OUT"}</p>
            <p>{props.secondDate}, {props.location + " - SOLD OUT"}</p>
            <p>{props.thirdDate}, {props.location}</p>
            <h2 onClick={() => clickMore("#section-places")}>Više</h2>
        </div>
  )
}

export default Event
