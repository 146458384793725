import "./SectionSponsors.css"

import { Swiper, SwiperSlide } from 'swiper/react';
import { isMobile } from 'react-device-detect';

import 'swiper/css';
import "swiper/css/pagination";

import { Pagination, Navigation, Autoplay } from "swiper";

const SectionSponsors = () => {
  var slidesPerView = isMobile ? 1 : 3;
  return (
    <div className="section-sponsors" id="section-sponsors">
      <div className="heading">
        <p className="red">HOSTED BY</p>
        <h1>Hvala Vam na podršci</h1>
      </div>
      <div className="container">
      <Swiper
      spaceBetween={0}
      slidesPerView={slidesPerView}
      pagination={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
     }}
      modules={[Pagination, Navigation, Autoplay]}
      className={"sponsors-swiper"}>
      <SwiperSlide><div className="sponsor-icon tresorevents"/></SwiperSlide>
      <SwiperSlide><div className="sponsor-icon amusic"/></SwiperSlide>
      <SwiperSlide><div className="sponsor-icon clubtresorvienna"/></SwiperSlide>
      </Swiper>
      </div>
    </div>
  )
}

export default SectionSponsors
