import "./SectionMap.css"

const SectionMap = () => {
  return (
    <div className="section-map">
      <div className="map"><img src="/images/map-2.svg" alt="Map"></img></div>
      <div className="informations">
        <div className="column">
            <div className="info">
                <div className="color color-blue"/>
                <div className="text">
                  <p>PARTER</p>
                  <p>40€</p>
                </div>
            </div>
            <div className="info">
                <div className="color color-orange"/>
                <div className="text">
                <p>FAN PIT</p>
                <p>65€</p>
                </div>
            </div>
        </div>
        <div className="column">
            <div className="info">
                <div className="color color-pink"/>
                <div className="text">
                <p>TRIBINE SEDEĆA</p>
                <p>MESTA 60€</p>
                </div>
            </div>
            <div className="info">
                <div className="color color-red"/>
                <div className="text">
                <p>VIP</p>
                <p>90€</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default SectionMap
