import {BrowserView, MobileView} from 'react-device-detect';
import "./Ticket.css"

const Ticket = (props) => {
  const navigateToHome = () => {
    // 👇️ navigate to /contacts
    if(props.link !== "")
       window.open(props.link, '_blank', 'noreferrer');
  };

  return (
      <div className={`ticket ${props.styleName }`}>
        <div className="column column-first">
          <div className="ticket-info">
        <div className="icon"><svg xmlns="http://www.w3.org/2000/svg" className="center icon-calendar" viewBox="0 0 448 512"><path d="M400 64h-48V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H128V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zM48 96h352c8.822 0 16 7.178 16 16v48H32v-48c0-8.822 7.178-16 16-16zm352 384H48c-8.822 0-16-7.178-16-16V192h384v272c0 8.822-7.178 16-16 16z"/></svg></div>
        <div className="date">
          <BrowserView>
            <h1>{props.date}.</h1>
            <p>{props.month}</p>
          </BrowserView>
          <MobileView>
          <h1>{props.date}. {props.month}</h1>
          </MobileView>
        </div>
        </div>
        <div className="ticket-name">
            <h1>{props.name}</h1>
        </div>
        </div>
        <div className="column">
        <div className="ticket-info">
        <div className="icon"><svg xmlns="http://www.w3.org/2000/svg" className="center icon-location" viewBox="0 0 384 512"><path d="M192 96c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 160c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64zm0-256C85.961 0 0 85.961 0 192c0 77.413 26.97 99.031 172.268 309.67 9.534 13.772 29.929 13.774 39.465 0C357.03 291.031 384 269.413 384 192 384 85.961 298.039 0 192 0zm0 473.931C52.705 272.488 32 256.494 32 192c0-42.738 16.643-82.917 46.863-113.137S149.262 32 192 32s82.917 16.643 113.137 46.863S352 149.262 352 192c0 64.49-20.692 80.47-160 281.931z"/></svg></div>
        <div className="location">
            <h1>{props.location}</h1>
            <h2>{props.info}</h2>
        </div>
        </div>
        <div className="button" onClick={() => navigateToHome()}><div className="button-border-top"></div><p>{props.button}</p><div className="button-border-bottom"></div></div>
        </div>
      </div>
  )
}

export default Ticket
