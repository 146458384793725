import "./SectionEvents.css"
import Events from "../Events/Events"

const SectionEvents = () => {
  return (
    <div className="section-events">
        <Events/>
    </div>
  )
}

export default SectionEvents
