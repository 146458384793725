import "./Tickets.css";
import Ticket from "../Ticket/Ticket";

const Tickets = () => {
  const soldTickets = [
    {
      id: "0",
      name: "Aleksandra Prijović",
      date: "05",
      month: "Oktobar",
      location: "Parter",
      info: "Hallmann dome",
      button: "SOLD OUT",
      link: "",
    },
    {
      id: "1",
      name: "Aleksandra Prijović",
      date: "05",
      month: "Oktobar",
      location: "FanPit",
      info: "Hallmann dome",
      button: "SOLD OUT",
      link: "",
    },
    {
      id: "2",
      name: "Aleksandra Prijović",
      date: "05",
      month: "Oktobar",
      location: "Tribine sedeća mesta",
      info: "Hallmann dome",
      button: "SOLD OUT",
      link: "",
    },
    {
      id: "3",
      name: "Aleksandra Prijović",
      date: "05",
      month: "Oktobar",
      location: "VIP",
      info: "Hallmann dome",
      button: "SOLD OUT",
      link: "",
    },
  ];
  const tickets = [{
      id: "0",
      name: "Aleksandra Prijović",
      date: "06",
      month: "Oktobar",
      location: "Parter",
      info: "Hallmann dome",
      button: "KUPI KARTU",
      link: "https://events.tickethead.io/tickethead/event/1045",
    },
    {
      id: "1",
      name: "Aleksandra Prijović",
      date: "06",
      month: "Oktobar",
      location: "FanPit",
      info: "Hallmann dome",
      button: "KUPI KARTU",
      link: "https://events.tickethead.io/tickethead/event/1045",
    },
    {
      id: "2",
      name: "Aleksandra Prijović",
      date: "06",
      month: "Oktobar",
      location: "Tribine sedeća mesta",
      info: "Hallmann dome",
      button: "KUPI KARTU",
      link: "https://events.tickethead.io/tickethead/event/1045",
    },
    {
      id: "3",
      name: "Aleksandra Prijović",
      date: "06",
      month: "Oktobar",
      location: "VIP",
      info: "Hallmann dome",
      button: "KUPI KARTU",
      link: "https://events.tickethead.io/tickethead/event/1045",
    },
  ];

  return (
    <div className="tickets">
      {soldTickets.map((ticket) => (
        <Ticket
          key={ticket.id}
          id={ticket.id}
          name={ticket.name}
          date={ticket.date}
          month={ticket.month}
          location={ticket.location}
          info={ticket.info}
          button={ticket.button}
          link={ticket.link}
          styleName={
            ticket.button === "SOLD OUT" ? "sold" : ""
          }
        />
      ))}
      {tickets.map((ticket) => (
        <Ticket
          key={ticket.id}
          id={ticket.id}
          name={ticket.name}
          date={ticket.date}
          month={ticket.month}
          location={ticket.location}
          info={ticket.info}
          button={ticket.button}
          link={ticket.link}
          styleName={
            ticket.id % 2 === 0 ? "gradient-1" : ""
          }
        />
      ))}
    </div>
  );
};

export default Tickets;