import "./Footer.css"

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="column">
          <div className="info">
          <h1>About us</h1>
          <div className="contact-info">
          <a href="tel:+43 667 772 1111">+43 667 772 1111</a>
          <a href="mailto:office@tresorevents.at">office@tresorevents.at</a>
          </div>
          </div>
      </div>
      <div className="separator"/>
      <div className="column">
      <div className="info">
          <h1>HALLMAN DOME</h1>
          <h2>START 19:00h</h2>
          <a href="http://maps.google.com/?q=1200 Gutheil-Schoder-Gasse 9, 1100 Wien" className="address">Gutheil-Schoder-Gasse 9, 1100 Wien</a>
          <p>AFTER PARTY MORE INFO SOON</p>
        </div>
      </div>
      </div>
      <div className="footer-copyright"><p>Tresor Club 2024. All Rights Reserved.</p><div className="finity"><a href="https://finity.pro">FINITY.PRO</a></div></div>
    </div>
  )
}

export default Footer
