import "./Posters.css"
import Poster from "../Poster/Poster"

import React, { useState, useRef } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import "swiper/css/pagination";

import { Pagination, Navigation, Autoplay } from "swiper";

import ImageViewer from "../ImageViewer/ImageViewer";

const Posters = () => {

  const [viewerOpen, setViewerOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const swiperRef = useRef(null);

  const openViewer = () => {
    swiperRef.current.swiper.autoplay.stop()
    setViewerOpen(true);
  };

  const closeViewer = () => {
    swiperRef.current.swiper.autoplay.start()
    setViewerOpen(false);
  };

  const posters = [
    { id: "0", src: "/images/Concerts/aleksandra-prijovic-D3.jpg" }
  ];

  return (
    <div className="posters">
      <Swiper
      spaceBetween={0}
      slidesPerView={1}
      pagination={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      onSlideChange={(swiper) => {
        setActiveIndex(swiper.activeIndex);
      }}
      ref={swiperRef}
      modules={[Pagination, Navigation, Autoplay]}
      onClick={() => { openViewer(); }}
      className={"posters-swiper"}>
        {posters.map(poster => (
        <SwiperSlide key={poster.id}><Poster
          src={poster.src}
          className={"visible"}
        /></SwiperSlide>
      ))}
      </Swiper>

      <ImageViewer
        images={posters}
        isOpen={viewerOpen}
        closeViewer={closeViewer}
        startIndex={activeIndex}
      />
    </div>
  )
}

export default Posters
