import "./Events.css"
import Posters from "../Posters/Posters"
import Event from "../Event/Event"
import { useState } from "react";

const Events = () => {
  return (
    <div className="events">
      <h1 className="red">TOURS & EVENTS</h1>
      <div className="event-list">
        <Event
          name={"KONCERT Aleksandra Prijović"}
          date={"04. Oktobar"}
          location={"HALLMANN DOME"}
          secondDate={"05. Oktobar"}
          thirdDate={"06. Oktobar"}
        />
      </div>
      <Posters/>
    </div>
  )
}

export default Events
