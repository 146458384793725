import "./Cover.css"
import React from "react";

const Cover = () => {
  const onClick = (id) => window.location.href = id;
  return (
    <div className="cover">
      <div className="column">
        <h2 className="red">DOBRODOŠLI</h2>
        <h1> TRESOR EVENTS.AT </h1>
        <p> ALEKSANDRA PRIJOVIĆ </p>
        <h2 className="red more"> POGLEDAJ VIŠE </h2>
        <div className="buttons">
        <div className="button" onClick={() => onClick("#section-tickets")}><div className="button-border-top"></div><p>ONLINE KARTE</p><div className="button-border-bottom"></div></div>
        <div className="button" onClick={() => onClick("#section-places")}><div className="button-border-top"></div><p>PRODAJNA MESTA</p><div className="button-border-bottom"></div></div>
        </div>
      </div>
    </div>
  )
}

export default Cover
